import React from 'react'
import classNames from 'classnames'

import SvgSprite from '../SvgSprite'

import styles from './styles.module.scss'

const policy = [
    {
        text:
            'We do not share personal information with companies, organizations and individuals outside of Calaton Studio unless one of the following circumstances applies:',
        subtext: [
            'You consent to us sharing your personal information',
            'A law enforcement agency has made a lawful request for the information',
            'For external processing. We use third party services to process your data, make payments, and deliver our service.',
        ],
    },
    {
        text:
            'We collect data about you and your studies using proprietary technology and third party tools. Our primary goals in collecting personally identifiable information are to provide you with a personalised service, to communicate with you, and to track our marketing and outreach efforts, including those of our affiliates.',
    },
    {
        text:
            'We use both session cookies and persistent cookies to better understand how you interact with our website.',
    },
    {
        text:
            'In addition, we may use your contact information to inform you about our products and services, and products and services of other third party products we recommend. If you decide at any time that you no longer wish to receive such information or communications from us, please un-follow us.',
    },
    {
        text:
            'You may request that personal information we have about you is deleted by emailing us. We will guarantee that the information is deleted within one calendar year from the date of the request.',
    },
]

const getNestedList = subtext => {
    return (
        <ul className={styles.nestedList}>
            {subtext.map((text, i) => (
                <li key={i}>{text}</li>
            ))}
        </ul>
    )
}

const classNamesSection = classNames('container', styles.privacy)

const PrivacyPage = () => {
    return (
        <section className={classNamesSection}>
            <h1 className={styles.title}>Privacy Policy</h1>
            <ul className={styles.privacyList}>
                {policy.map(({ text, subtext }, i) => (
                    <li className={styles.item} key={i}>
                        {text}
                        {subtext && getNestedList(subtext)}
                        <SvgSprite name="circleIcon" className={styles.svgSpriteFirst} />
                        <SvgSprite name="circleIcon" className={styles.svgSpriteSecond} />
                    </li>
                ))}
            </ul>
        </section>
    )
}

export default PrivacyPage
