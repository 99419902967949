import React from 'react'

import BaseContainer from 'src/components/BaseContainer'
import Header from 'src/components/Header'
import PrivacyPage from 'src/components/PrivacyPage'
import Footer from 'src/components/Footer'
import Meta from 'src/components/Meta'
import ogLogoCompany from 'src/images/optimized/jpg/cases/og-logo_company.jpg'

const metaData = {
    title: 'Calaton Studio - Privacy Policy',
    description: 'Privacy Policy of Calaton Studio.',
    url: 'https://www.calaton.studio/privacy',
    img: `https://www.calaton.studio${ogLogoCompany}`,
    keywords: 'privacy policy, Calaton Studio',
}

const Privacy = () => (
    <>
        <Meta content={metaData} />
        <BaseContainer>
            <Header />
            <PrivacyPage />
            <Footer />
        </BaseContainer>
    </>
)

export default Privacy
